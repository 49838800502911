.App {
  font-family: 'Verdana';
  text-align: center;
  width: calc(100vw - 20px);
  margin: 3em 0;
  padding: 0 80px;
  font-size: 13px; /* default sem bootstrap.min.css */
}

body {
  margin: 0;
}

.link {
  display: flex;
  margin: 10px 0;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  text-decoration-line: underline;
}

/* LINK HOVER COLOR */
.link:hover {
  color: white;
}

.results-list option {
  background-color: transparent;
  color: inherit;
}


.top-containers {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 80px;
}





div > h2 {
  font-weight: bold;
}









/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/
/* DEFAULT EPISODE */
/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

/* BG PATTERN AND BASE FONT COLOR */
.global-default {
  color: #dfd0bb;
  background: url(https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/headers/bbg.jpeg);
  /* background: #17140e; */
}

/* TITLE & LINK FONT COLOR */
.title-default {
  color: #ed9e19;
}

/* CONTAINERS + TEXTBOXES + MENUS + LISTS --- BG COLOR + BORDER COLOR */
.global-default input, .global-default select, .background-default  {
  background: #282218EC;
  border: 0.5px solid #dfd0bb;
  color: inherit; /* Must be present, otherwise defaults as black font */
}






/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/
/* ALPHA */
/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

/* BG PATTERN/ULTRA BG COLOR AND BASE FONT COLOR */
.global-00 {
  color: #dfd0bb;
  /* background: #271a30; */
  background: linear-gradient(1turn, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url(https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/bgs/bg-00.png);
}

/* TITLE & LINK FONT COLOR */
.title-00 {
  color:rgb(238, 163, 218);
}

/* CONTAINERS + TEXTBOXES + MENUS + LISTS --- BG COLOR + BORDER COLOR */
.global-00 input, .global-00 select, .background-00  {
  background: #4c3055EC;
  border: 0.5px solid #dfd0bb;
  color: inherit; /* Must be present, otherwise defaults as black font */
}

  






/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/
/* EP 01 */
/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

/* BG PATTERN AND BASE FONT COLOR */
.global-1 {
  color: #dfd0bb;
  background: linear-gradient(1turn, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url(https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/bgs/bg-1.jpg);
/*   background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1.0)),
              url(https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/bgs/bg-1.jpg); */
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

/* TITLE & LINK FONT COLOR */
.title-1 {
  color: #ed9e19;
}

/* CONTAINERS + TEXTBOXES + MENUS + LISTS --- BG COLOR + BORDER COLOR */
.global-1 input, .global-1 select, .background-1  {
  background: rgba(40, 34, 24, 0.93); /* 80% opacity */
  /* background: #282218CC; */
  border: 0.5px solid #dfd0bb;

  color: inherit; /* Must be present, otherwise defaults as black font */
}


/* 
FF → 100% (fully opaque).
CC → 80%.
99 → 60%.
66 → 40%.
33 → 20%.
00 → 0% (fully transparent). */





